import { configureStore } from "@reduxjs/toolkit";
import instituteConfigReducer from "../features/instituteConfig";
import currentUserReducer from "../features/currentUser";
import checkAccessReducer from "../features/checkAccess";
import languageReducer from "../features/languageReducer"

export const store = configureStore({
  reducer: {
    instituteConfig: instituteConfigReducer,
    currentUser: currentUserReducer,
    userAccess: checkAccessReducer,
    language: languageReducer,
  },
});
